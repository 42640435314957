<template>
  <router-view />
</template>

<script>
export default {
  name: 'Signup',

  data: () => ({
    //
  }),
};
</script>

<style>
@import "../../../assets/signup.css";
</style>
