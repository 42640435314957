function isDigit(digit) {
  return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(digit);
}

function isTrue(value) {
  return ['true', 'True', '1', 't', 'T', 1, true].includes(value);
}

export {
  isDigit,
  isTrue,
};
