<template>
  <div
    class="m-spacer"
    :style="cssVars"
  />
</template>

<script>
export default {
  name: 'Spacer',

  components: {},

  props: {
    height: {
      type: String,
      default: '8px',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--height': this.height,
      };
    },
  },
};
</script>

<style>
.m-spacer {
  height: var(--height);
}
</style>
