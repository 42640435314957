<template>
  <div
    class="m-select-container"
    :style="cssVars"
  >
    <p class="m-select-label">
      {{ label }}
    </p>

    <v-select
      v-model="valueComputed"
      class="m-select"
      :items="items"
      outlined
      hide-details="auto"
    />
  </div>
</template>

<script>
import { secondaryTextColor } from '../../globals/colors';

export default {
  name: 'Select',

  components: {},

  props: {
    label: {
      type: String,
      default: 'Placeholder label',
    },

    items: {
      type: Array,
      default: () => [],
    },

    value: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--label-color': secondaryTextColor,
      };
    },

    valueComputed: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.m-select-container {
  padding-bottom: 8px;
}

.m-select.v-input .v-input__slot {
  border-radius: 8px;
}

p.m-select-label {
  margin: 8px 0px;
  font-weight: 500;
  color: var(--label-color);
  font-size: 16px;
  line-height: 20px;
}

.m-select-container .v-icon {
  color: rgba(1, 1, 1, 0.5);
}
</style>
