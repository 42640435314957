import Vue from 'vue';
import VueRouter from 'vue-router';

import {
  Login, Signup, SignupExternalExpert1, SignupExternalExpert2,
} from '../components/pages';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', name: 'Login', component: Login },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
      children: [
        {
          path: 'external/1',
          name: 'SignupExternalExpert1',
          component: SignupExternalExpert1,
        },
        {
          path: 'external/2',
          name: 'SignupExternalExpert2',
          component: SignupExternalExpert2,
        },
      ],
    },
  ],
});
