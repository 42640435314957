<template>
  <div class="m-link-container">
    <router-link
      :to="link"
      class="text-decoration-none"
      :style="cssVars"
    >
      {{ text }}
    </router-link>
  </div>
</template>

<script>
import { linkColor } from '../../globals/colors';

export default {
  name: 'Link',

  components: {},

  props: {
    text: {
      type: String,
      default: 'Placeholder Text',
    },

    link: {
      type: String,
      default: '#',
    },

    fontSize: {
      type: String,
      default: '16px',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--font-size': this.fontSize,
        '--color': linkColor,
      };
    },
  },
};
</script>

<style>
.m-link-container {
  padding-top: 8px;
  padding-bottom: 8px;
}

.m-link-container a {
  font-size: var(--font-size);;
}

.v-application .m-link-container a {
  color: var(--color);
}
</style>
