<template>
  <div
    class="m-checkbox-container"
    :style="cssVars"
  >
    <v-checkbox
      v-model="valueComputed"
      class="m-checkbox"
      :label="label"
      hide-details="auto"
    />
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  components: {},

  props: {
    label: {
      type: String,
      default: 'Placeholder Label',
    },

    value: {
      type: Boolean,
      default: false,
    },

    fontSize: {
      type: String,
      default: '16px',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--font-size': this.fontSize,
      };
    },

    valueComputed: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.m-checkbox-container {
  padding-top: 8px;
  padding-bottom: 8px;
}

.m-checkbox-container label {
  font-size: var(--font-size);
}

.m-checkbox.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 0px;
}
</style>
