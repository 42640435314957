<template>
  <v-btn
    block
    :color="color"
    class="white--text m-button"
    :type="type"
    @click="click"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: 'Button',

  components: {},

  props: {
    text: {
      type: String,
      default: 'Placeholder Text',
    },

    color: {
      type: String,
      default: 'black',
    },

    type: {
      type: String,
      default: 'submit',
    },

    click: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-btn:not(.v-btn--round).v-size--default.m-button {
  border-radius: 8px;
  height: 62px;
}

.v-btn--is-elevated.m-button {
  box-shadow: none;
}

.v-btn.m-button {
  text-transform: none;
  font-size: 18px;
}
</style>
