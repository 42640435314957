<template>
  <v-app>
    <v-main class="main">
      <router-view />
    </v-main>

    <Debug />
  </v-app>
</template>

<script>
import { Debug } from './components/pages';

export default {
  name: 'App',
  components: { Debug },

  data: () => ({
    //
  }),
};
</script>

<style>
@import "./assets/main.css";
</style>
