<template>
  <div class="m-upper-container justify-end">
    <div class="login-container m-white-shadowed-form">
      <div class="inner-container">
        <v-form
          class="m-main-form"
          @submit="onSubmit"
        >
          <Logo :src="require('@/assets/bms_logo.png')" />

          <Spacer height="13px" />

          <p class="font-weight-bold m-header">
            Войти
          </p>

          <Spacer height="12px" />

          <Input
            v-model="iinComputed"
            label="ИИН"
            type="text"
            max-length="17"
          />

          <Input
            v-model="values.password"
            label="Пароль"
            type="password"
          />

          <Link
            class="m-mr7"
            text="Забыли пароль?"
            font-size="12px"
            link="#"
          />

          <Checkbox
            v-model="values.rememberMe"
            class="m-mr7"
            font-size="12px"
            label="Запомнить меня?"
          />

          <Spacer height="28px" />

          <Button
            type="submit"
            :color="submitButtonColor"
            text="Войти"
          />

          <Spacer height="4px" />

          <Link
            class="text-center"
            text="Еще нет аккаунта? Зарегистрироваться"
            link="/signup/external/1"
          />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Checkbox, Spacer, Input, Link, Button, Logo,
} from '../common';
import { actions } from '../../store';

import { darkBlueGray } from '../../globals/colors';
import { isDigit } from '../../globals/utils';

export default {
  name: 'Login',

  components: {
    Input,
    Link,
    Checkbox,
    Spacer,
    Button,
    Logo,
  },

  data: () => ({
    values: {
      iinInternal: '',
      password: '',
      rememberMe: false,
    },

    submitButtonColor: darkBlueGray,
  }),

  computed: {
    iinComputed: {
      get() {
        return this.values.iinInternal;
      },

      set(value) {
        this.values.iinInternal = this.computeIin(value);
      },
    },
  },

  methods: {
    computeIin(val) {
      // Remove all non-digits
      let clearedValue = '';
      for (let i = 0; i < val.length; i++) {
        if (isDigit(val[i])) {
          clearedValue += val[i];
        }
      }

      // Add new spaces
      let valueWithSpaces = '';
      for (let i = 0; i < clearedValue.length; i++) {
        if (i % 2 === 0 && i !== 0) {
          valueWithSpaces += ` ${clearedValue[i]}`;
        } else {
          valueWithSpaces += clearedValue[i];
        }
      }

      return valueWithSpaces;
    },

    onSubmit(evt) {
      evt.preventDefault();
      const message = JSON.stringify(this.values, undefined, 2);
      actions.updateDebugMessage(message);
    },
  },
};
</script>

<style>
.login-container {
  width: 566px;
  padding: 48px 68px 48px 68px;
}

.login-container > .inner-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.m-mr7 {
  margin-left: 7px;
}
</style>
