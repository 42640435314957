<template>
  <div
    class="m-horizontal-spacer"
    :style="cssVars"
  />
</template>

<script>
export default {
  name: 'HorizontalSpacer',

  components: {},

  props: {
    width: {
      type: String,
      default: '8px',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--width': this.width,
      };
    },
  },
};
</script>

<style>
.m-horizontal-spacer {
  width: var(--width);
}
</style>
