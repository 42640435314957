<template>
  <p
    class="secondary-text"
    :style="cssVars"
  >
    {{ text }}
  </p>
</template>

<script>
import { secondaryTextColor } from '../../globals/colors';

export default {
  name: 'SecondaryText',

  components: {},

  props: {
    text: {
      type: String,
      default: 'Placeholder Text',
    },
  },

  data: () => ({
    //
  }),

  computed: {
    cssVars() {
      return {
        '--color': secondaryTextColor,
      };
    },
  },
};
</script>

<style>
.secondary-text {
    color: var(--color);
    font-size: 16px;
}

.v-application p.secondary-text {
    margin-bottom: 0px;
}
</style>
