<template>
  <div
    class="m-pagination align-center"
    :style="cssVars"
  >
    <router-link
      v-if="left !== ''"
      :to="left"
      class="m-pagination-link m-pagination-link-left"
      @click.native="clickLeft"
    >
      <ArrowLeft />
      <HorizontalSpacer :width="space" />
      <span>Назад</span>
    </router-link>

    <router-link
      v-if="right !== ''"
      :to="right"
      class="m-pagination-link m-pagination-link-right"
      @click.native="clickRight"
    >
      <span>Дальше</span>
      <HorizontalSpacer :width="space" />
      <ArrowRight />
    </router-link>
  </div>
</template>

<script>
import { darkBlueGray } from '../../globals/colors';
import HorizontalSpacer from './HorizontalSpacer.vue';
import { ArrowRight, ArrowLeft } from './icons';

export default {
  name: 'Pagination',

  components: {
    HorizontalSpacer,
    ArrowRight,
    ArrowLeft,
  },

  props: {
    fontSize: {
      type: String,
      default: '18px',
    },

    left: {
      type: String,
      default: '',
    },

    right: {
      type: String,
      default: '',
    },

    clickLeft: {
      type: String,
      default: () => {},
    },

    clickRight: {
      type: String,
      default: () => {},
    },
  },

  data: () => ({
    height: '62px',
    opacity: 1,
    space: '10px',
  }),

  computed: {
    cssVars() {
      return {
        '--height': this.height,
        '--text-color': darkBlueGray,
        '--font-size': this.fontSize,
        '--opacity': this.opacity,
      };
    },
  },
};
</script>

<style scoped>
.m-pagination {
  height: var(--height);
  display: flex;
}

.v-application a.m-pagination-link {
  height: 22px;
  display: flex;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
  font-size: var(--font-size);
  opacity: var(--opacity);
}

.v-application a.m-pagination-link-left {
  margin-right: auto;
}

.v-application a.m-pagination-link-right {
  margin-left: auto;
}
</style>
