<template>
  <div class="m-upper-container justify-center">
    <div class="signup-container m-white-shadowed-form">
      <v-form class="signup-form">
        <Logo :src="require('@/assets/bms_logo.png')" />

        <Spacer height="15px" />

        <v-row class="m-signup-row d-flex justify-space-between align-center">
          <p class="font-weight-bold m-header mb-0">
            Создать аккаунт
          </p>

          <Link
            text="Уже есть аккаунт? Войти"
            font-size="14px"
            link="/login"
          />
        </v-row>

        <Spacer height="20px" />

        <SecondaryText text="Личные данные 2 из 4" />

        <Spacer height="24px" />

        <v-row class="m-signup-row">
          <v-col>
            <SecondaryText text="Диплом об ученой степени" />

            <Spacer height="16px" />

            <FileInput label="Прикрепить файл" />
          </v-col>

          <HorizontalSpacer width="20px" />

          <v-col>
            <SecondaryText text="Св. о присвоении категории" />

            <Spacer height="16px" />

            <FileInput label="Прикрепить файл" />
          </v-col>
        </v-row>

        <Spacer height="16px" />

        <v-row class="m-signup-row">
          <v-col>
            <Select
              v-model="values.degree"
              label="Ученая степень"
              :items="selectItems.degree"
            />

            <Select
              v-model="values.expertiseSubject"
              label="Предмет экспертизы"
              :items="selectItems.expertiseSubject"
            />

            <Select
              v-model="values.sanat"
              label="Санаты"
              :items="selectItems.sanat"
            />
          </v-col>

          <HorizontalSpacer width="20px" />

          <v-col>
            <Input
              v-model="values.expertiseWithoutBreak"
              label="Стаж в экспертизе"
            />

            <Input
              v-model="values.expertiseBreak"
              label="Перерыв в экспертизе"
            />

            <Input
              v-model="values.expertiseOverall"
              label="Общий стаж в экспертизе"
            />
          </v-col>
        </v-row>

        <Spacer height="165px" />

        <Pagination
          left="/signup/external/1"
          right="/signup/external/3"
          :click-left="onLeft"
          :click-right="onRight"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
  Logo,
  Link,
  SecondaryText,
  FileInput,
  Input,
  HorizontalSpacer,
  Select,
  Pagination,
} from '../../common';
import { actions } from '../../../store';
import { darkBlueGray } from '../../../globals/colors';

export default {
  name: 'SignupExternalExpert2',

  components: {
    Spacer,
    Logo,
    Link,
    SecondaryText,
    FileInput,
    Input,
    HorizontalSpacer,
    Select,
    Pagination,
  },

  data: () => ({
    submitButtonColor: darkBlueGray,
    selectItems: {
      degree: ['Бакалавр', 'Магистр', 'PhD'],
      expertiseSubject: ['Software Engineering', 'Data Science', 'Some Other'],
      sanat: ['Санат 1', 'Санат 2'],
    },

    values: {
      degree: '',
      expertiseSubject: '',
      sanat: '',
      expertiseWithoutBreak: 0,
      expertiseBreak: 0,
      expertiseOverall: 0,
    },
  }),

  methods: {
    onLeft() {
      const message = JSON.stringify(this.values, undefined, 2);
      actions.updateDebugMessage(message);
    },

    onRight() {
      const message = JSON.stringify(this.values, undefined, 2);
      actions.updateDebugMessage(message);
    },
  },
};
</script>
