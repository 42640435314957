<template>
  <div class="m-upper-container justify-center">
    <div class="signup-container m-white-shadowed-form">
      <v-form class="m-main-form">
        <Logo :src="require('@/assets/bms_logo.png')" />

        <Spacer height="15px" />

        <v-row class="m-signup-row d-flex justify-space-between align-center">
          <p class="font-weight-bold m-header mb-0">
            Создать аккаунт
          </p>

          <Link
            text="Уже есть аккаунт? Войти"
            font-size="14px"
            link="/login"
          />
        </v-row>

        <Spacer height="20px" />

        <SecondaryText text="Личные данные 1 из 4" />

        <Spacer height="24px" />

        <v-row class="m-signup-row">
          <v-col>
            <SecondaryText text="Удо. лич." />

            <Spacer height="16px" />

            <FileInput label="Прикрепить файл" />
          </v-col>

          <HorizontalSpacer width="20px" />

          <v-col />
        </v-row>

        <Spacer height="16px" />

        <v-row class="m-signup-row">
          <v-col>
            <Select
              v-model="values.status"
              label="Статус"
              :items="selectItems.status"
            />

            <Input
              v-model="values.firstName"
              label="Фамилия"
            />

            <Input
              v-model="values.lastName"
              label="Имя"
            />

            <Input
              v-model="values.middleName"
              label="Отчество"
            />
          </v-col>

          <HorizontalSpacer width="20px" />

          <v-col>
            <Input
              v-model="values.iin"
              label="ИИН"
              type="password"
            />

            <Input
              v-model="values.password"
              label="N% уд. лич."
              type="password"
            />

            <Input
              v-model="values.phoneNumber"
              label="Моб. номер"
              type="password"
            />

            <Input
              v-model="values.email"
              label="Email"
            />
          </v-col>
        </v-row>

        <Spacer height="65px" />

        <Pagination
          right="/signup/external/2"
          :click="onRight"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
  Logo,
  Link,
  SecondaryText,
  FileInput,
  Input,
  HorizontalSpacer,
  Select,
  Pagination,
} from '../../common';
import { actions } from '../../../store';
import { darkBlueGray } from '../../../globals/colors';

export default {
  name: 'SignupExternalExpert1',

  components: {
    Spacer,
    Logo,
    Link,
    SecondaryText,
    FileInput,
    Input,
    HorizontalSpacer,
    Select,
    Pagination,
  },

  data: () => ({
    values: {
      status: null,
      firstName: '',
      lastName: '',
      middleName: '',
      iin: '',
      rememberMe: false,
      phoneNumber: '',
      email: '',
    },

    submitButtonColor: darkBlueGray,
    selectItems: {
      status: ['Активный', 'Неактивный'],
    },
  }),

  methods: {
    onRight() {
      const message = JSON.stringify(this.values, undefined, 2);
      actions.updateDebugMessage(message);
    },
  },
};
</script>
