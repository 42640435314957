<template>
  <div
    v-if="isDebug"
    class="m-debug-container"
  >
    <div
      class="m-debug-container-inner"
      :style="styleComputed"
    >
      <pre class="m-debug-content">{{ state.debugMessage }}</pre>
    </div>

    <div class="m-debug-fab">
      <v-btn
        fab
        @click="show = !show"
      >
        <v-icon dark>
          {{ show ? closeIcon : openIcon }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { state } from '../../store';
import constants from '../../globals/constants';

export default {
  name: 'Debug',

  data: () => ({
    show: true,
    width: '400px',
    padding: '18px',
    closeIcon: 'mdi-arrow-collapse-left',
    openIcon: 'mdi-arrow-collapse-right',
    debugContent: '',
    state,
    isDebug: constants.DEBUG,
  }),

  computed: {
    styleComputed() {
      const width = this.show ? this.width : '0px';
      const padding = this.show ? this.padding : '0px';
      return `width: ${width}; padding: ${padding}`;
    },
  },
};
</script>

<style>
.m-debug-container {
  left: 0;
  position: absolute;
  width: fit-content;
  height: 100%;
  display: flex;
}

.m-debug-container-inner {
  left: 0;
  height: 100%;
  background: rgba(1, 1, 1, 0.7);
}

.m-debug-content {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Courier New', monospace;
  color: white;
  overflow: hidden;
}

.m-debug-fab {
  width: fit-content;
  height: fit-content;
  padding: 16px;
}
</style>
