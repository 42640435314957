<template>
  <div
    class="m-input-container"
    :style="cssVars"
  >
    <p class="m-input-label">
      {{ label }}
    </p>

    <div v-if="type === 'password'">
      <v-text-field
        v-model="valueComputed"
        outlined
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        class="m-input"
        :type="show ? type : 'password'"
        hide-details="auto"
        :max-length="maxLength"
        @click:append="show = !show"
      />
    </div>

    <div v-else>
      <v-text-field
        v-model="valueComputed"
        outlined
        class="m-input"
        :type="type"
        hide-details="auto"
        :max-length="maxLength"
      />
    </div>
  </div>
</template>

<script>
import { secondaryTextColor } from '../../globals/colors';

export default {
  name: 'Input',

  components: {},

  props: {
    label: {
      type: String,
      default: 'Placeholder label',
    },

    type: {
      type: String,
      default: 'text',
    },

    value: {
      type: String,
      default: '',
    },

    maxLength: {
      type: String,
      default: '17px',
    },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    cssVars() {
      return {
        '--label-color': secondaryTextColor,
      };
    },

    valueComputed: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.m-input-container {
  padding-bottom: 8px;
}

.m-input.v-input .v-input__slot {
  border-radius: 8px;
}

p.m-input-label {
  margin: 8px 0px;
  font-weight: 500;
  color: var(--label-color);
  font-size: 16px;
  line-height: 20px;
}

.m-input-container .v-icon {
  color: rgba(1, 1, 1, 0.5);
}
</style>
