<template>
  <v-img
    class="m-logo"
    max-height="59"
    max-width="121"
    :src="src"
  />
</template>

<script>
export default {
  name: 'Logo',

  components: {},

  props: {
    src: {
      type: String,
      default: '#',
    },
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.m-logo {
  /* TODO: Remove when correct logo is provided */
  position: relative;
  left: -10.5px;
}
</style>
