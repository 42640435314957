<template>
  <v-file-input
    :label="label"
    outlined
    hide-details="auto"
  />
</template>

<script>
export default {
  name: 'FileInput',

  components: {},

  props: {
    label: {
      type: String,
      default: 'Placeholder Label',
    },
  },

  data: () => ({
    //
  }),
};
</script>
