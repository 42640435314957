import Vue from 'vue';

const state = Vue.observable({
  debugMessage: 'Debugging text',
});

const actions = {
  updateDebugMessage(message) {
    state.debugMessage = message;
  },
};

export {
  state,
  actions,
};
