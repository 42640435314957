import { isTrue } from './utils';

const {
  VUE_APP_BACKEND_BASE_URL: BACKEND_BASE_URL,
  VUE_APP_DEBUG,
} = process.env;

export default {
  BACKEND_BASE_URL,
  DEBUG: isTrue(VUE_APP_DEBUG),
};
